@import "./style/reset"

@import "./style/constant"
@import "./style/typography"

// GLOBAL STYLES/BASELINES
body
  color: $text_primary
  font-family: "Helvetica Neue", sans-serif
  font-size: 16px
